import Alpine from 'alpinejs'
import Litepicker from 'litepicker'
import tippy from 'tippy.js'
import Swal from 'sweetalert2'
import dateFormat from 'dateformat'
import TomSelect from 'tom-select'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import '../css/theme/select.css'
import 'tom-select/dist/css/tom-select.bootstrap5.min.css'

require('./bootstrap')

const DOMPurify = require('dompurify')

window.DOMPurify = DOMPurify
window.Alpine = Alpine
window.Litepicker = Litepicker
window.tippy = tippy
window.Swal = Swal
window.dateFormat = dateFormat
window.TomSelect = TomSelect

Alpine.start()
